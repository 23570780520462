import React from 'react';
import IndividualChildPhase from './IndividualChildPhase';
import './childphaselist.css';


const ChildPhaseList = ({ list }) => {
  let childPhaseList = <h3>Loading...</h3>;
  // console.log('input from parent:', list);

  if (list) {
    childPhaseList = list.map((m, i) => <IndividualChildPhase key={i} item={m} />);
  }

  return (
    <div className="pdf-container">
        <div className="pdf-header">
          <div className="pdf-header-name">
            Phase Name
          </div>
          <div className="pdf-header-name">
            Phase ID
          </div>
          <div className="pdf-header-name">
            Address
          </div>
          <div className="pdf-header-name">
            Unit No.
          </div>
          <div className="pdf-header-name">
            No. of Units
          </div>
          <div className="pdf-header-name">
            Building No.
          </div>
        </div>
        <div className="pdf-body">
            {childPhaseList}
        </div>
    </div>
  )
}
export default ChildPhaseList;