import React, { useState } from "react";
import {
  Grid
} from "@material-ui/core";
import "./DeleteDuplicate.css";
import DeleteDuplicateForm from "./DeleteDuplicateForm";
import DuplicateSuccessPage from "./DuplicateSuccessPage";
import ReviewDuplicates from "./ReviewDuplicates"; 
import VerticalLinearStepper from "../VerticalLinearStepper/VerticalLinearStepper";
import axios from "axios";

const DeleteDuplicate = () => {
  const [stepCount, setStepCount] = React.useState(0);
  const incrementCounter = () => setStepCount(stepCount + 1);
  const decrementCounter = () => setStepCount(stepCount - 1);
  const [formData, setFormData] = useState({
    currentProject: "",
    duplicateProject: "",
    phases: [],
    certifications: [],
    updateBy: "",
    projectDetails: null,
    certificationList: [],
    formCertificationList: [],
    deleteProjectDetails: null,
    isConfirm: false
  });
  React.useEffect(() => {
  }, []);

  const userRole = sessionStorage.getItem("role");

  const getPage = () => {
    switch (stepCount) {
        case 0:
          return ( <DeleteDuplicateForm  incrementCounter={incrementCounter}  setFormData={setFormData} formData={formData}/>);
        case 1:
          return ( <ReviewDuplicates decrementCounter={decrementCounter} incrementCounter={incrementCounter} formData={formData} setFormData={setFormData}/>);
        case 2:
          return ( <DuplicateSuccessPage  formData={formData}></DuplicateSuccessPage >)
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={2} style={{ backgroundColor: "#F2F2F2" }}>
        <VerticalLinearStepper
              steps={["Delete duplicate configuration", "Review & Save", "Final Confirmation"]}
              activeStep={stepCount}
        />
        </Grid>
        <Grid
          container
          item
          xs={10}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ padding: "20px", border: "solid 1px black" }}
        >
          {getPage()}
        </Grid>
      </Grid>
    </>
  );
};

export default DeleteDuplicate;
