import React from "react";
import IndividualPhase from "./IndividualPhase";
import "./PhaseList.css";
import { FaSort } from "react-icons/fa";

const PhaseList = ({ list }) => {
  let phase = <h3>Loading...</h3>;
  // console.log('input from parent:', list);

  if (list) {
    phase = list.map((m, i) => <IndividualPhase key={i} item={m} />);
  }

  return (
    <div className="pdf-container">
      <div className="pdf-header">
        <div className="pdf-header-name" style={{flex:"0.30"}}>Phase Name</div>
        <div className="pdf-header-name">Phase ID</div>
        <div className="pdf-header-name">Address</div>
        <div className="pdf-header-name">Unit No.</div>
        <div className="pdf-header-name">No. of Units</div>
        <div className="pdf-header-name">Building No.</div>
        <div className="pdf-header-name">Certification Status</div>
      </div>
      <div className="pdf-body">{phase}</div>
    </div>
  );
};
export default PhaseList;
