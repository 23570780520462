import React from "react";
import IndividualPhase from "./IndividualPhase";
import "./PhaseList.css";
import { FaSort } from "react-icons/fa";
import { CircularProgress } from "@material-ui/core";

const PhaseList = ({ list }) => {
  let phase = (
    <div style={{ textAlign: "center" }}>
      <CircularProgress style={{ marginTop: "20px", color: "#1176b5" }} />
    </div>
  );

  if (list) {
    phase = list.map((m, i) => <IndividualPhase key={i} item={m} />);
  }

  return (
    <div className="phase-container">
      <div className="phase-header">
        <div className="phase-header-name" style={{ flex: "0.30" }}>
          Phase Name
        </div>
        <div className="phase-header-name">Phase ID</div>
        <div className="phase-header-name">Address</div>
        <div className="phase-header-name">Unit No.</div>
        <div className="phase-header-name">No. of Units</div>
        <div className="phase-header-name">Building No.</div>
        <div className="phase-header-name">Certification Status</div>
      </div>
      <div className="phase-body">{phase}</div>
    </div>
  );
};
export default PhaseList;
